<template>
	<div id="main-wrapper" class="inner-pages forex-page pamm-page ib-page">
		<div class="section banner-holder">
			<div class="forex-nav">
				<div class="wrapper">
					<ul>
						<li><router-link to="/white-label">White Label</router-link></li>
						<li><router-link to="/pamm">PAMM/MAM</router-link></li>
						<li class="active"><router-link to="/ib">Introducing Broker</router-link></li>
					</ul>
				</div>
			</div>
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>Introducing Broker </h2>
				<p>Develop a permanent source of income for yourself</p>
			</div>
		</div>
		<div class="pamm-content section">
			<div class="wrapper">
				<div class="title-holder wow fadeIn">
					<h3>Success today is about building a strong network, let your network work on earning money for you, even when you sleep!</h3>
				<br>	<p>Want to bring out the best in your marketing skills? AAAFx has brought up an enriching program for our clients to spread the word about our fantastic platform and earn unlimited income. Simply invite traders to us and earn commission when they trade!</p>
				</div>
			</div>
			<div class="full-img wow fadeIn">

			</div>
		</div>
		<div class="expect-sec section">
			<div class="wrapper">
				<div class="img-holder wow fadeInLeft">
					<img src="assets/images/ib-img2.webp" alt="AAAFx" title="AAAFx" width="564" height="551" class="fluid-img" />
				</div>
				<div class="content-holder wow fadeInRight">
					<div class="title-holder">
						<h3>Spread the word- Create an impact in the trading community</h3>
						<p>We offer outstanding trading conditions and tools for traders and help our IB partners grow along with their clients:</p>
						<ul class="listing-check">
							<li>High liquidity for trading</li>
							<li>Zero commissions on Forex</li>
							<li>Raw Spreads</li>
							<li>High Leverage up to 500X</li>
							<li>Trusted and reliable broker</li>
							<li>Access to Trading tools like Auto chartist</li>
							<li>Direct access to Zulu Trade</li>
							<li>Cutting Edge Technology</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="section join-ib">
			<div class="wrapper">
				<div class="title-holder t-left">
					<h3>Why should you join in as AAAFx IB?</h3> <br><br>
					<p>Reward for your smart-work and consistency can be huge if you keep growing our trading community, helping them make use of diverse features of our trading platforms.
					<br><br>
					At AAAFx, we offer the most customized and the simplest IB program. Not all IBs are the same. But we have something to offer for everyone.
					<br><br>
					Hence, we have made two categories of IB programs:
					<br><br>
					<span>1.</span>Standard IB Program- Straightaway get 20% of our commission earnings from your clients. This is useful for a beginner IB who wants to start afresh or can start to bring a limited volume of clients to us.
					<br><br>
					<span>2.</span>Advanced IB Program- This is a highly rewarding and customized IB program for our bigger IBs. This is useful for someone with an substantial client base who can bring in larger volume.
					<br><br>
					You can move from Standard IB Program to the Advanced IB Program, once you have made your network large enough.
					</p>
				</div>
				<div class="listing">
					<div class="join-holder col wow fadeIn">
						<p>Sign-up as IB</p>
						<img src="assets/images/ib-ico1.webp" alt="AAAFx" title="AAAFx" width="185" height="193" class="fluid-img" />
					</div>
					<div class="join-holder col wow fadeIn" data-wow-delay="0.3s">
						<p>Share Referral link</p>
						<img src="assets/images/ib-ico2.webp" alt="AAAFx" title="AAAFx" width="185" height="193" class="fluid-img" />
					</div>
					<div class="join-holder col wow fadeIn" data-wow-delay="0.6s">
						<p>Get your clients</p>
						<img src="assets/images/ib-ico3.webp" alt="AAAFx" title="AAAFx" width="185" height="193" class="fluid-img" />
					</div>
					<div class="join-holder col wow fadeIn" data-wow-delay="0.9s">
						<p>Earn as they trade</p>
						<img src="assets/images/ib-ico4.webp" alt="AAAFx" title="AAAFx" width="185" height="193" class="fluid-img" />
					</div>
				</div>
			</div>
		</div>
		<div class="section benefits-ib wow fadeIn">
			<div class="wrapper">
				<div class="title-holder wow fadeIn">
					<h3>Other benefits of our IB Program</h3>
				</div>
				<div class="benefits-holder col">
					<img src="assets/images/ib1.webp" alt="AAAFx" title="AAAFx" width="94" height="94" class="fluid-img" />
					<p>Earn with Zero Investment or Experience</p>
				</div>
				<div class="benefits-holder col">
					<img src="assets/images/ib2.webp" alt="AAAFx" title="AAAFx" width="94" height="94" class="fluid-img" />
					<p>Weekly IB commissions pay-outs</p>
				</div>
				<div class="benefits-holder col">
					<img src="assets/images/ib3.webp" alt="AAAFx" title="AAAFx" width="94" height="94" class="fluid-img" />
					<p>Unlimited Earnings potential</p>
				</div>
				<div class="benefits-holder col">
					<img src="assets/images/ib4.webp" alt="AAAFx" title="AAAFx" width="94" height="94" class="fluid-img" />
					<p>Highly customized and rewarding Advanced IB Program</p>
				</div>
				<div class="benefits-holder col">
					<img src="assets/images/ib5.webp" alt="AAAFx" title="AAAFx" width="94" height="94" class="fluid-img" />
					<p>Ultra-fast withdrawals of IB commissions</p>
				</div>
				<div class="benefits-holder col">
					<img src="assets/images/ib6.webp" alt="AAAFx" title="AAAFx" width="94" height="94" class="fluid-img" />
					<p>Easy User Interface of IB Portal</p>
				</div>
				<div class="benefits-holder col">
					<img src="assets/images/ib7.webp" alt="AAAFx" title="AAAFx" width="94" height="94" class="fluid-img" />
					<p>Dedicated Account Manager</p>
				</div>
				<div class="benefits-holder col">
					<img src="assets/images/ib8.webp" alt="AAAFx" title="AAAFx" width="94" height="94" class="fluid-img" />
					<p>Helpful and Free Marketing Material </p>
				</div>
			</div>
		</div>
		<div class="section-small ">
			<div class="wrapper">
				<div class="title-holder">
					<h6>Our Advanced IB Program is highly customizable with high income potential. If you think you have that potential, get <br>in touch with us to know more. Contact Us now <a href="mailto:support@aaafx.com.">here </a> or write to us at  <a href="mailto:support@aaafx.com.">support@aaafx.com.</a></h6>

				</div>
			</div>
		</div>

	</div>
</template>